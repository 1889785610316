import {
    required,
    url
} from 'vuelidate/lib/validators';

export const BulkRules = {
    url_in_bulk: {
        required,
        urlBulkValidation(value) {
            if (!this.$v.url_in_bulk.required || !value) return true;
            return new Promise((resolve) => {
                const arrayUrl = value.split('\n')
                resolve(arrayUrl.reduce((acc, link) => acc && url(link), true))
            })
        }
    }
}
