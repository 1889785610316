export const editorItems = {
    single: [{
        name: 'url',
        component: 'name'
    }],
    absent: [
        {
            name: 'url',
            component: 'name'
        },
        {
            name: 'price',
            component: 'name'
        },
        {
            name: 'type_payment',
            component: 'select'
        },
        {
            name: 'info_payment',
            component: 'name'
        }
    ],
    bulk: [
        {
            name: 'url_in_bulk',
            component: 'textarea'
        }
    ]
}
