import editorBaseMixin from '@/mixins/editor/editorBaseMixin';
import { editorItems } from '@/helpers/constants/edit-outreach-page/editor-items'

// Validation
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
// import { validationRules } from '@/validation/CreateSeoLink/Rules'
import { AbsetEmilRules } from '@/validation/CreateSeoLink/AbsetEmilRules'
import { AbsetUrlRules } from '@/validation/CreateSeoLink/AbsetUrlRules'
import { BulkRules } from '@/validation/CreateSeoLink/BulkRules'
import { SingleRules } from '@/validation/CreateSeoLink/SingleRules'
import { formMessages } from '@/validation/CreateSeoLink/Messages'

import { mapState, mapMutations } from 'vuex'

import { mapSeoFields } from '@/store/modules/seo/seo-edit/index.js'

import {
    RESET_CREATED_FORM_DATA
} from '@/store/modules/permissions/permissions-edit/mutation-types'

import { isEqual } from 'lodash'

export default {
    mixins: [
        editorBaseMixin,
        validationMixin,
        formValidationMixin
    ],
    validations() {
        const rules = {
            single: {
                ...SingleRules
            },
            absent: this.form_data.type_payment === 'paypal_email' ? { ...AbsetEmilRules } : { ...AbsetUrlRules },
            bulk: {
                ...BulkRules
            }
        }
        return rules[this.type]
    },
    data() {
        return {
            type: 'absent'
        }
    },
    watch: {
        form_data: {
            deep: true,
            handler() {
                if (!this.checkIsEqual) {
                    this.unfinished_session = true
                    this.unfinished_url = this.$route.path
                    return
                }
                this.unfinished_session = false
                this.unfinished_url = null
            }
        }
    },
    computed: {
        editorItems() {
            return editorItems[this.type]
        },
        ...mapState('auth', ['user']),
        ...mapSeoFields([
            'outreach',
            'outreach.form_data',
            'outreach.form_data.url',
            'outreach.form_data.price',
            'outreach.form_data.type_payment',
            'outreach.form_data.info_payment',
            'outreach.form_data.url_in_bulk',
            'loading'
        ]),
        checkIsEqual() {
            return isEqual(this.form_data, this.default_form_data)
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapMutations('seoEdit', [RESET_CREATED_FORM_DATA]),
        back() {
            this.$router.back()
        }
    }
}
