<template>
    <editor-layout :loading="loading">
        <template #header>
            <editor-header
                :title="'New Outreach'"
            />
        </template>
        <template #content>
            <div class="checkbox_wrap">
                <form-checkbox
                    :disabled="loading"
                    label="The link is already placed on th donor"
                    color="$sidebar-orange"
                    :value="type === 'bulk'"
                    @input="changeBulk"
                />
            </div>
            <div class="topBaner">
                <ul>
                    <li
                        v-for="(item, index) in bannerItems"
                        :key="index"
                    >
                        {{ item }}
                    </li>
                </ul>
            </div>
            <form @submit.prevent="openSaveModal">
                <component
                    :is="'editor-' + editorItem.component"
                    v-for="(editorItem, id) in editorItems"
                    :key="id"
                    v-model="$v[editorItem.name].$model"
                    :class="`${editorItem.name}-editor-field`"
                    :disabled="loading"
                    :items="[
                        { text: 'Paypal invoice', value: 'paypal_invoice' },
                        { text: 'Paypal email', value: 'paypal_email' }
                    ]"
                    :config="user"
                    :label="editorItem.name"
                    :legend="getLinksCount"
                    :field.sync="$v[editorItem.name].$model"
                    :errors="(serverErrors[editorItem.name] && serverErrors[editorItem.name][0]) || validationMessage($v[editorItem.name])"
                    :is-valid="$v[editorItem.name].$dirty && !$v[editorItem.name].$anyError"
                    @input.native="inputHandeler(editorItem)"
                    @blur.native="$v[editorItem.name].$touch()"
                    @keydown.native.space="(event) => event.preventDefault()"
                />
                <div class="editor-form__buttons">
                    <custom-button
                        :disabled="loading"
                        default
                        type="submit"
                    >
                        CREATE OUTREACH
                    </custom-button>
                    <custom-button
                        :disabled="loading"
                        default
                        type="button"
                        @on-btn-click="openResetModal"
                    >
                        RESET FORM
                    </custom-button>
                </div>
            </form>
        </template>
        <template #modals>
            <confirm-modal
                :title="'Reset SeoLink?'"
                :content="'Are you sure?'"
                :name="'reset-modal'"
                @onConfirmClick="reset"
            />
            <confirm-modal
                :title="'Save SeoLink?'"
                :content="'Are you sure?'"
                :name="'save-modal'"
                @onConfirmClick="checkRouteForValidate"
            />
        </template>
    </editor-layout>
</template>

<script>
import pbnMixin from '@/mixins/editor/outreach-page'
import { urlValidator } from '@/services/seoLinksService'

import {
    ACTION_CREATE_OUTREACH_POST
} from '@/store/modules/seo/seo-edit/action-types'
import { createNamespacedHelpers } from 'vuex';
import { errorHandler } from '@/services/errorHandelerServeice'

const {
    mapActions: mapSeoEditActions
} = createNamespacedHelpers('seoEdit');

export default {
    name: 'CreateOutreachPage',
    mixins: [
        pbnMixin
    ],
    data() {
        return {
            bannerItems: [
                'Negotiation is done with the seller',
                'We have payment information and price',
                'The link will be added to our list so that we can make a payment to the seller',
                'The link needs to be verified/pinged once placed by by the seller'
            ],
            serverErrors: {},
            timer: null
        }
    },
    computed: {
        getLinksCount() {
            if (this.type === 'bulk') {
                return `${this.url_in_bulk.split('\n').filter((item) => !!item).length} links`
            }
            return ''
        }
    },
    methods: {
        ...mapSeoEditActions([
            ACTION_CREATE_OUTREACH_POST
        ]),
        changeBulk() {
            if (this.type === 'bulk') {
                this.type = 'absent'
                return
            }
            this.type = 'bulk'
        },
        submit() {
            this.serverErrors = {}
            this[ACTION_CREATE_OUTREACH_POST]({ data: { ...this.form_data }, type: this.type })
                .then((msg = 'Your outreach link was created') => {
                    this.$bus.$emit('showSnackBar', msg, 'success')
                    this.$v.$reset()
                    this.$router.push({ name: 'posts-list-outreach' })
                }).catch((err) => {
                    if (this.type === 'bulk') {
                        const { inputError, errorSnackBarText } = errorHandler.getBulkError(err?.response?.data || {})
                        this.$set(this.serverErrors, 'url_in_bulk', [inputError])
                        this.$bus.$emit('showSnackBar', errorSnackBarText, 'error')
                    } else {
                        this.serverErrors = err?.response?.data?.errors
                        const { errorSnackBarText } = errorHandler.singleServerError(err?.response?.data || {})
                        if (!this.serverErrors) {
                            this.serverErrors = {}
                            this.$set(this.serverErrors, 'url', [errorSnackBarText])
                        }
                        this.$bus.$emit('showSnackBar', errorSnackBarText, 'error')
                    }
                })
        },
        checkRouteForValidate() {
            if (this.$route.name === 'create-outreach') {
                this.validate()
            }
        },
        reset() {
            if (this.$route.name === 'create-outreach') {
                this.RESET_CREATED_FORM_DATA({ name: 'outreach' })
                this.$v.$reset()
            }
        },
        inputHandeler(editorItem) {
            this.serverErrors[editorItem.name] = []
        },
        async validateUrl(url) {
            return new Promise((resolve, reject) => {
                clearTimeout(this.timer)
                this.timer = setTimeout(async () => {
                    try {
                        await urlValidator(url)
                        resolve()
                    } catch (e) {
                        reject(e)
                    }
                }, 500)
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .topBaner {
        background: #3872ca;
        color: #fff;
        padding: 15px;
        border-radius: 3px;
        margin-bottom: 30px;
    }
    .checkbox_wrap {
        margin: 20px auto;
    }
</style>

<style lang="scss">
    .url_in_bulk-editor-field textarea{
        height: 500px;
        font-size: 16px;
        line-height: 35px;
    }
</style>
