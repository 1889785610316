import {
    required,
    url
} from 'vuelidate/lib/validators';

export const SingleRules = {
    url: {
        required,
        url
    }
}
