import {
    email,
    required,
    url
} from 'vuelidate/lib/validators';

export const AbsetEmilRules = {
    url: {
        required,
        url,
        urlValidation(value) {
            if (!this.$v.url.required || !this.$v.url.url || !value) return true;
            return new Promise((resolve) => {
                this.validateUrl(value)
                    .then(() => {
                        resolve(true)
                    })
                    .catch((err) => {
                        this.$set(this.serverErrors, 'url', err?.response?.data?.errors?.url)
                        console.log(this.serverErrors)
                        resolve(true)
                    })
            })
        }
    },
    price: {
        required
    },
    type_payment: {
        required
    },
    info_payment: {
        required,
        email
    }
}
